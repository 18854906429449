<template>
    <div class="page_content">
        <div class="page_edit_content page_detail">
            <Form
                ref="form"
                :form_data="form_data"
                @handleSubmit="handleSubmit"
            ></Form>
        </div>
    </div>
</template>

<script>
import LookImages from "@/components/LookImages";
import { contractTemplateDeteail, saveContractTempate, getCostType } from '@/api/cost'
import Form from "@/components/Form";
const columns = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
];
export default {
    components: {
        LookImages,
        Form
    },
    data() {
        return {
            columns,
            detail: {
                goods_data: []
            },
            form_data: {
                show_submit_btn: true,
				close_reset_btn: true,
				show_close_btn: true,
                list: [
                    {
                        type: "text",
                        name: "id",
                        hidden: true,
                        options: {
                            initialValue: ""
                        }
                    },
                    {
                        type: "text",
                        name: "title",
                        title: "合约模板标题",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入合约模板标题"
                                }
                            ],
                            initialValue: ""
                        }
                    },
      //               {
      //                   type: "tree-select",
      //                   name: "type_id",
      //                   title: "适用投放类型",
      //                   options: {
						// 	rules: [
						// 		{
						// 			required: true,
						// 			message: "请选择适用投放类型"
						// 		}
						// 	]
						// },
      //                   treeData: [],
      //                   multiple: true
      //               },
					{
						type: "radio",
						name: "default",
						title: "是否为默认模板",
						options: {},
						list: [
							{
								key: 1,
								value: "是"
							},
							{
								key: 0,
								value: "否"
							}
						],
					},
					{
					    type: "editor",
					    name: "template",
					    title: "完整模板内容",
					    options: {},
					    treeData: [],
					    multiple: true,
						tips: "--type_name-- : 投放类型\n--goods-- : 具体要求\n--date-- : 投放期限\n--month-- : 时长\n--number-- : 保证品项数量\n--decompose-- : 销量分解\n--reward-- : 费用品项信息\n--remark-- : 其他约定"
					},
					{
					    type: "editor",
					    name: "brief_template",
					    title: "简略模版",
					    options: {},
					    treeData: [],
					    multiple: true,
						tips: "--type_name-- : 投放类型\n--goods-- : 具体要求\n--date-- : 投放期限\n--month-- : 时长\n--number-- : 保证品项数量\n--decompose-- : 销量分解\n--reward-- : 费用品项信息\n--remark-- : 其他约定"
					},
                ]
            }
        }
    },
    created() {
		if(this.$route.query.id){
			this.get_info()
		}
    },
    methods: {
        get_info() {
            contractTemplateDeteail({
                data: {
                    id: this.$route.query.id,
					type:2
                }
            }).then(res => {
                this.form_data.list.forEach((item, index) => {
                    if (item.name == 'department_id') {
                        let department_id = res.data.list.department_id
                        if (department_id.length == 1 && department_id[0] == '') {
                            return
                        }
                        this.$refs.form.setFieldsValue({
                            department_id: res.data.list.department_id.map(i => parseInt(i))
                        })
                    } else if (item.type == 'editor') {
                        this.form_data.list[index].content = res.data.list[item.name]
                    } else {
                        this.$refs.form.setFieldsValue({
                            [item.name]: res.data.list[item.name]
                        })
                    }
                })
            })
        },
        handleSubmit({ values }) {
            saveContractTempate({
                data: {
                    ...values,
					type:2
                },
                info: true
            }).then(res => {
                this.$router.back()
            })
        }
    }
}
</script>

<style>
</style>